<!-- 头条资讯-资讯分类列表 -->
<template>
  <div class="headlineclass">
	<headlinehead></headlinehead>
	<div class="groupclass">
		<headlineclasslist class='headlineclasslist'></headlineclasslist>
		<div class="rt">
      <headlinelimt class='headlinelimt'></headlinelimt>
      <headlineltnew class='headlineltnew'></headlineltnew>
      <headlineltmade class='headlineltmade'></headlineltmade>
      <headlinelttip class='headlinelttip'></headlinelttip>
      <headlinelthot class='headlinelthot'></headlinelthot>
    </div>
	</div>
  	<div class="likesAuto">
  		<likes class='likes'></likes>
  		<infiniteImg type='0' class='infiniteImg'></infiniteImg>
  	</div>
  </div>
</template>
<script>
import likes from '@/components/likes/likes.vue';//猜你喜欢
import infiniteImg from '@/components/infiniteImg/infiniteImg.vue';//底部滚动展示
import headlinehead from '@/components/headline-head/headline-head.vue';//头条资讯-头部
import headlineclasslist from '@/components/headline-classlist/headline-classlist.vue';//头条资讯-列表分类
import headlinelimt from '@/components/headline-limt/headline-limt.vue';//头条资讯-右侧限时
import headlinelthot from '@/components/headline-lthot/headline-lthot.vue';//头条资讯-右侧热销
import headlineltnew from '@/components/headline-ltnew/headline-ltnew.vue';//头条资讯-右侧最新资讯
import headlineltmade from '@/components/headline-ltmade/headline-ltmade.vue';//头条资讯-右侧定制案例
import headlinelttip from '@/components/headline-lttip/headline-lttip.vue';//头条资讯-右侧热门标签
export default {
  name: 'headlineclass',
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
        name: "description",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition
      },
      {
        name: "Keywords",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey
      }
        ]
    }
  },
  data(){
  	return {
  	  htmltitle:'头条资讯-微微定',
  	  carouselsData:[
  	  	// {src:require('../assets/img/carousel/carousel.jpg')},{src:require('../assets/img/carousel/carousel1.jpg')},{src:require('../assets/img/carousel/carousel2.jpg')},
  	  ],
  	}
  },
  components: {
  	likes,
  	infiniteImg,
  	headlinehead,
  	headlineclasslist,
  	headlinelimt,
  	headlinelthot,
  	headlineltnew,
  	headlineltmade,
  	headlinelttip
  },
  mounted(){
  	this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b,_pomise.c])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
  	})
  }
}
</script>
<style lang="scss" scoped>
.headlineclass {
	.groupclass {
		width: 1200px;zoom:1;margin:0 auto;margin-top:20px;
		.headlineclasslist {
			float:left;
		}
    .rt {
      float:right;width: 380px;
    }
		.headlinelimt {
			float:right;
		}
		.headlinelthot {
			float:right;margin-top:10px;
		}
		.headlineltnew {
			float:right;margin-top:10px;
		}
		.headlineltmade {
			float:right;margin-top:10px;
		}
		.headlinelttip {
			float:right;margin-top:10px;
		}
		&:after {
			content: "";display: block;clear:both;
		}
	}
	.likesAuto {
		width: 1200px;margin:0 auto;margin-top:40px;
		.infiniteImg {
			margin-top:40px;
		}
	}
}
</style>
